.container-fluid {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
}
.scrip_header {
  background: #73c2fb;
}
.scrip__nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.scrip__title-scrip {
  display: flex;
  align-items: center;
  gap: 10px;
}
.scrip__title-scrip h1 {
  font-size: 27px;
  color: white;
  font-family: "Noto Sans JP", sans-serif;
}
.scrip__title_right {
  display: flex;
  align-items: center;
  gap: 30px;
}
.scrip__title_right h3 {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 18px;
  color: white;
}
.ant-col-16 {
  max-width: unset;
}
.paragraph__list {
  width: 500px;
}
.savedata__title {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 18px;
  color: #000;
}
.paragraph__list__span {
  border: 1px solid #edecec;
  width: 100%;
}
.paragraph__title {
  margin-bottom: 35px;
  margin-top: 20px;
}
.paragraph__title h2 {
  font-size: 19px;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 600;
  margin-block-start: unset;
  margin-top: 15px;
}
.topic {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 15px;
  margin-block-end: 10px;
  font-weight: 400;
}
.keywords {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 15px;
  margin-block-end: 10px;
  font-weight: 400;
}
.keywords span {
  font-size: 12px;
  font-family: "Noto Sans JP", sans-serif;
  margin-block-end: 10px;
  font-weight: 400;
}
.select__option {
  display: flex;
  gap: 20px;
  align-items: center;
}
.ant-select-selector {
  padding: unset;
  padding-left: 10px !important;
  padding-right: 50px !important;
}
.center__tab {
  width: 100%;
  /* max-width: 1050px; */
  height: 700px;
  padding: 20px;
  background-color: #fff;
  margin-top: 15px;
  border: 1px solid #edeef1;
  overflow: auto;
  border-radius: 15px;
}
.card__option__item {
  /* display: flex; */
  gap: 20px;
  justify-content: center;
  padding-left: 112px;
  padding-right: 70px;
}
@media screen and (max-width: 1280px) {
  .center__tab {
    height: 500px;
  }
}
.center__title {
  /* display: flex; */
  align-items: center;
  gap: 10px;
}
.center__title p {
  margin-bottom: unset;
}
.entry {
  font-size: 18px;
  font-family: "Noto Sans JP", sans-serif;
  overflow: hidden;
  /* border-right: 2px solid #000;  */
  padding-right: 8px;
  animation: blink-caret 0.75s step-end infinite;
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  60% {
    border-color: #000;
  }
}
.ant-card-head {
  border-bottom: unset !important;
  padding: 15px 12px !important;
}
.right__tab {
  margin-top: 15px;
  height: 800px;
  width: 450px;
  overflow: auto;
  max-width: 100%;
  padding: 10px;
}
.right__tab_card {
  width: 40px;
  display: flex;
  height: 40px;
  background-color: #bbf7d0;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.right__money {
  font-size: 1.5rem;
  font-weight: 700;
}
.card__tab {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.list__tools_text h6 {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 18px;
  margin-block-end: unset;
  margin-block-start: unset;
  font-weight: 700;
}
.list__tools_text span {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 12px;
  color: #ee7d95;
}
.list__tools_text div {
  border: 1px solid #edecec;
  margin-top: 20px;
}
.socialmedia__ai h6 {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 15px;
  margin-block-end: unset;
  font-weight: 700;
}
.socialmedia__ai span {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 12px;
  color: #ee7d95;
}
.socialmedia__ai ul {
  margin-block-start: unset;
  margin-block-end: unset;
}
.clear {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
  margin-top: 20px;
}
.border__all {
  border: 1px solid #edecec;
}
.footer__card {
  width: 40px;
  display: flex;
  height: 40px;
  background-color: #ffe4e5;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.footer__text {
  margin-top: 20px;
  font-size: 17px;
  font-weight: 800;
  font-family: "Noto Sans JP", sans-serif;
}
.footer__span {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Noto Sans JP", sans-serif;
}
.about {
  text-align: center;
}
.about span {
  font-size: 15px;
  font-weight: 700;
  font-family: "Noto Sans JP", sans-serif;
}
.footer__section {
  margin-bottom: 20px;
}
.homepage__section {
  text-align: center;
  margin-top: 150px;
}
.homepage h1 {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 70px;
  margin-block-end: unset;
  font-weight: 700;
}
.homepage span {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 70px;
  margin-block-end: unset;
  font-weight: 700;
  color: #73c2fb;
}
.bp5-icon-arrow-right {
  color: white !important;
}
.table__component {
  display: flex;
  align-items: center;
  gap: 10px;
}
.table__component {
  margin: 20px;
  font-family: Arial, sans-serif;
}

.table__component table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.table__component th,
.table__component td {
  border: 1px solid #ddd;
  padding: 8px;
}
.signbutton {
  display: flex;
  align-items: center;
  gap: 10px;
}
.google__acc_image {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.table__component th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.table__component tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table__component tr:hover {
  background-color: #f1f1f1;
}
.googleLogIn{
border-radius: 12px !important;
border: 0 !important;
padding: none !important;
}
.googleLogIn div{
  border-radius: 12px !important;
  border: 0 !important;
  padding: none !important;
  }
.aitools__display {
  display: flex;
  gap: 30px;
  /* flex-direction: column; */
  flex-wrap: wrap;
}
@media screen and (max-width: 1700px) {
  .ant-card {
    width: 250px !important;
  }
}
@media screen and (max-width: 1700px) {
  .ant-card {
    width: 232px !important;
  }
}
@media screen and (max-width: 1250px) {
  .card__option__item {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1065px) {
  .card__option__item {
    justify-content: center;
    gap: 30px;
  }
  .ant-card {
    width: 330px !important;
  }
}
@media screen and (max-width: 1880px) {
  .center__tab {
    width: unset; /* Full width of the container */
    max-width: unset !important; /* Max width limited to 1050px */
    height: 700px;
    padding: 20px;
    background-color: #fff;
    margin-top: 15px;
    border: 1px solid #edeef1;
    overflow: auto;
    border-radius: 15px;
  }
}
@media screen and (max-width: 1440px) {
  .center__tab {
    height: 500px;
  }
}
@media screen and (max-width: 769px) {
  .ant-card {
    width: 320px !important;
  }
  .container-fluid {
    flex-direction: column;
  }
  .paragraph__list {
    width: 100%;
  }
  .ant-form {
    max-width: 100% !important;
  }

  .footer__section-display {
    flex-direction: column;
  }
  .bp5-card {
    width: 100% !important;
  }
  .scrip__title-scrip h1 {
    font-size: 21px !important;
  }
  .font {
    font-size: 8px;
  }
  .googleIcon {
    width: 15px;
    height: 15px;
  }
  .scrip__title_right h3 {
    font-size: 13px;
  }
  .scrip__title_right {
    gap: 10px;
  }
  .homepage h1 {
    font-size: 36px !important;
  }
  .homepage span {
    font-size: 45px !important;
  }
}

@media screen and (max-width: 678px) {
  .ant-card {
    width: 275px !important;
  }
}
@media screen and (max-width: 678px) {
  .ant-card {
    width: 100% !important;
  }
  .card__option__item {
    flex-direction: column;
    padding-left: unset;
    padding-right: unset;
  }
  .right__tab {
    height: 100%;
  }
  .widt__media{
    width: 100%;
  }
}
